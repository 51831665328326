// src/AppNavigator.js

import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Welcome from './signup/Welcome';
import Signup from './signup/Signup';
import Login from './signup/Login';
import PasswordReset from './signup/PasswordReset';
import Home from './Home';
import About from './About';
import FAQ from './faq';
import SubmitTrip from './SubmitTrip';
import Contact from './Contact';
import Trips from './Trips';
import Search from './Search';
import PrivacyPolicy from './PrivacyPolicy';
import Terms from './Terms';
import ClosestAirports from './pages/closest-airports';
import SpaBreaks from './pages/spa-breaks';
import './styles/style.css';

const AppNavigator = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} title="Home"/>
        <Route path="/about" element={<About />} title="About"/>
        <Route path="/faq" element={<FAQ />} title="FAQs"/>
        <Route path="/signup" element={<Signup />} title="Signup"/>
        <Route path="/contact" element={<Contact />} title="Contact"/>
        <Route path="/trips" element={<Trips />} title="Trips"/>
        <Route path="/search" element={<Search />} title="Search"/>
        <Route 
          path="/welcome"
          title="Welcome" 
          element={
            <Welcome />
          }
        />
        <Route path="/login" element={<Login />} title="Login"/>
        <Route path="/password-reset" element={<PasswordReset />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/closest-airports" element={<ClosestAirports />} />
        <Route path="/spa-breaks" element={<SpaBreaks />} />
      </Routes>
    </div>
  );
};

export default AppNavigator;
