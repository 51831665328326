// src/utilities/useImageFetcher.js
import { useState, useEffect } from 'react';

const useImageFetcher = (city) => {
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
   const localCities = 
   [
    'aberdeen',
    'agadir-(temsia)',
    'alicante',
    'alderney',
    'amsterdam',
    'athens',
    'baden-baden',
    'barcelona',
    'belfast',
    'bergen',
    'berlin',
    'billund',
    'birmingham',
    'bilbao',
    'bologna',
    'bratislava',
    'bremen',
    'bristol',
    'brussels',
    'budapest',
    'bucharest',
    'catania',
    'charlestown',
    'cluj-napoca',
    'cologne',
    'copenhagen',
    'corfu',
    'cork',
    'dortmund',
    'douglas',
    'dublin',
    'düsseldorf',
    'edinburgh',
    'eindhoven',
    'exeter',
    'faro',
    'fes',
    'frankfurt',
    'fuerteventura',
    'fuerteventura-island',
    'gdansk',
    'gdańsk',
    'geneva',
    'gibraltar',
    'glasgow',
    'gothenburg',
    'gran-canaria-island',
    'helsinki',
    'innsbruck',
    'istanbul',
    'katowice',
    'kaunas',
    'killarney',
    'kirkwall',
    'knock',
    'krakow',
    'kraków',
    'lanzarote',
    'lanzarote-island',
    'larnaca',
    'las palmas',
    'leipzig',
    'liverpool',
    'lisbon',
    'london',
    'luxembourg',
    'lyon',
    'madrid',
    'majorca',
    'manchester',
    'málaga',
    'malaga',
    'marrakech',
    'marrakesh',
    'marseille',
    'milan',
    'naples',
    'newcastle',
    'newcastle-upon-tyne',
    'nice',
    'nápoli',
    'nürnberg',
    'nuremberg',
    'olbia',
    'oslo',
    'palma',
    'palma-de-mallorca',
    'palma,-majorca',
    'paris',
    'paphos',
    'pisa',
    'porto',
    'poznan',
    'poznań',
    'prague',
    'riga',
    'rome',
    'rovaniemi',
    'rotterdam',
    'salzburg',
    'santander',
    'santiago',
    'saint-helier',
    'saint-peter-port',
    'seville',
    'sevilla',
    'shetland-islands',
    'shannon',
    'sofia',
    'southampton',
    'stavanger',
    'stockholm',
    'stornoway',
    'tallinn',
    'tenerife',
    'tirana',
    'toulouse',
    'trieste',
    'valencia',
    'venice',
    'verona',
    'vienna',
    'vilnius',
    'warsaw',
    'wrocław',
    'zurich',
    'zürich'
];

    const fetchImage = async () => {
      if (localCities.includes(city.toLowerCase())) {
        // Use local image if the city matches one of the local image filenames
        
        // create const for image name, with spaces replaced with hypens and all lowercase
        const imageName = city.replace(/\s+/g, '-').toLowerCase();
        setImageUrl(`cities/${imageName}.jpg`);
      } else {
          console.log('City not found in local images = ', city);
          setImageUrl('default.jpg'); // Set a default image path
      }
    };

    if (city && city !== 'default-city') {
      fetchImage();
    } else {
      setImageUrl('default.jpg'); // Set a default image path
    }
  }, [city]);

  return imageUrl;
};

export default useImageFetcher;
