import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../AuthContext'; // Import AuthContext
import '../styles/style.css';
import { Helmet } from 'react-helmet';

const Welcome = () => {
  const { currentUser } = useContext(AuthContext); // Access current user from context
  let navigate = useNavigate();

  const goBack = () => {
    navigate('/'); // Navigate back to the home page
  };

  useEffect(() => {
    if (currentUser) {
      navigate('/'); // Navigate to chat if there's a logged-in user
    }
  }, [currentUser, navigate]); // Depend on currentUser and navigate

  return (
    <div className="welcome-container">
      <Helmet>
        <title>Login to Extreme Day Trips</title>
        <meta name="description" content="Start your adventure with Extreme Day Trips. Sign up or log in to discover and book exciting day-long trips and connect with other adventure enthusiasts." />
        <meta name="keywords" content="Extreme Day Trips, welcome, sign up, log in, adventure trips" />
        <meta property="og:title" content="Welcome to Extreme Day Trips" />
        <meta property="og:description" content="Join Extreme Day Trips today. A platform for thrill-seekers to book and share experiences of day-long adventure trips." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://extremedaytrips.com/welcome" />
        <meta property="og:image" content="https://extremedaytrips.com/path-to-your-og-image.jpg" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Welcome to Extreme Day Trips" />
        <meta name="twitter:description" content="Get started with Extreme Day Trips. Sign up or log in to access a world of day-long adventure trips." />
        <meta name="twitter:image" content="https://extremedaytrips.com/path-to-your-twitter-card-image.jpg" />
        {/* Add any additional SEO related tags as needed */}
      </Helmet>
      <div className="back-arrow" onClick={goBack}>&#x2190;</div>
      <div className="welcome-content">
        <h1>Welcome to Extreme Day Trips!</h1>
        <div className="signup-benefits">
  <p><strong>Create your free account to:</strong></p>
  <ul>
    <li>
      ✅ Unlock flight searches
    </li>
    <li>
      ✅ Find additional flights in the search results
    </li>
    <li>
      ✅ EDT flight deals newsletter for your airport (totally optional)
    </li>
</ul>
<br/>
  <p>🚀 Start exploring today to find your next Extreme Day Trip!</p>
</div>
        <button className="welcome-button signup" onClick={() => navigate('/signup')}>
          Create Free Account
        </button>
        <button className="welcome-button welcome" onClick={() => navigate('/login')}>
         Login
        </button>

      </div>
    </div>
  );
};

export default Welcome;
