// src/utilities/fetchFlights.js or src/fetchFlights.js

export const transformFlightData = (flightData, setWarningMessage) => {
    let ryanairFlightFound = false;

    const transformedData = flightData.map(flight => {
      const departureTime = flight.route[0].local_departure;
      const returnTime = flight.route[flight.route.length - 1].local_departure;

      // Filter out undefined or empty flight numbers before joining
      const flightNumbers = flight.route
        .map(segment => segment.operating_carrier && segment.operating_flight_no ? `${segment.operating_carrier}${segment.operating_flight_no}` : null)
        .filter(Boolean)
        .join(", ");

      // Check if any of the flight numbers start with "FR" (Ryanair)
      if (flightNumbers.startsWith("FR")) {
        ryanairFlightFound = true;
      }

      return {
        departureAirport: flight.flyFrom,
        arrivalAirport: flight.flyTo,
        departureDateTime: departureTime,
        returnDateTime: returnTime,
        destinationCity: flight.cityTo,
        flightNumber: flightNumbers, // Updated flight numbers
        price: parseFloat(flight.price),
        bookingToken: flight.booking_token, // Add the booking token
        submittedAt: new Date().toISOString(),
        destinationCountryName: flight.countryTo?.name || null,
        destinationCountryCode: flight.countryTo?.code || null,
      };
    });

    // Set warning message if no Ryanair flights are found
    if (!ryanairFlightFound) {
      setWarningMessage("Ryanair flights may not be shown in this search");
    } else {
      setWarningMessage(null); // Clear the warning message if Ryanair flights are found
    }

    return transformedData;
  };

  export const fetchFlights = async (
    { 
      departureAirport = '', 
      departureDate = '', // Used as a default for date_from and date_to if they are not provided
      returnDate = '', // Optionally provided to override return_from and return_to
      priceTo = '1000', 
      currency = 'gbp', 
      nightsInDstFrom = '0', 
      nightsInDstTo = '1', 
      sort = 'price', 
      asc = '1', 
      limit = '50', 
      dtimeFrom = '04:00', 
      dtimeTo = '10:00', 
      retDtimeFrom = '16:00', 
      retDtimeTo = '23:00', 
      retFromDiffCity = 'false',
      // New parameters for explicit control over dates, with defaults linked to departureDate
      dateFrom = departureDate, 
      dateTo = departureDate, 
      returnFrom = returnDate || departureDate, 
      returnTo = returnDate || departureDate 
    },
    shouldSetState, 
    setIsLoading, 
    setError, 
    setSearchResults, 
    setErrorMessage, 
    searchPerformed, 
    setWarningMessage
  ) => {
    setIsLoading(true);
    setError(null);
  
    // Format the dates
    const formattedDateFrom = dateFrom ? dateFrom.split('-').reverse().join('/') : '';
    const formattedDateTo = dateTo ? dateTo.split('-').reverse().join('/') : '';
    const formattedReturnFrom = returnFrom ? returnFrom.split('-').reverse().join('/') : '';
    const formattedReturnTo = returnTo ? returnTo.split('-').reverse().join('/') : '';
  
    // Construct the API URL with potentially different values for date_from, date_to, return_from, return_to
    const apiUrl = `https://flight-search-api-767212907233.europe-west2.run.app/search-flights?fly_from=${departureAirport}&date_from=${formattedDateFrom}&date_to=${formattedDateTo}&return_from=${formattedReturnFrom}&return_to=${formattedReturnTo}&price_to=${priceTo}&curr=${currency}&nights_in_dst_from=${nightsInDstFrom}&nights_in_dst_to=${nightsInDstTo}&sort=${sort}&asc=${asc}&limit=${limit}&dtime_from=${dtimeFrom}&dtime_to=${dtimeTo}&ret_dtime_from=${retDtimeFrom}&ret_dtime_to=${retDtimeTo}&ret_from_diff_city=${retFromDiffCity}`;
    
    console.log(apiUrl);

    try {
      const response = await fetch(apiUrl);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const { flights } = await response.json();
      if (flights.length === 0) {
        if (searchPerformed) {
          setErrorMessage("No flights were found for the selected criteria.");
        }
        return [];
      }
      const transformedData = transformFlightData(flights, setWarningMessage);
      if (shouldSetState) {
        setSearchResults(transformedData);
      }
      return transformedData;
    } catch (error) {
      console.error('Fetch flights error:', error);
      setErrorMessage('Failed to fetch flights. Please try again later.');
      return [];
    } finally {
      console.log("fetch finished");
    }
  };
  