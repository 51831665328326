// src/components/NewsBanner.js
import React, { useState, useEffect, useMemo } from 'react';
import '../styles/NewsBanner.css';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const NewsBanner = () => {
  const newsItems = useMemo(() => [
    {
      id: '20241114',
      expiryDate: '2024-11-14T00:00:00Z',
      message: `We've improved the search performance so you can find EDTs around 4 times quicker than before! \n⚡ Updates to our mobile apps are on the way too! 📲`
    },
    {
      id: '20241115',
      expiryDate: '2024-11-31T00:00:00Z',
      message: `Now added Liverpool to the weekly EDT newsletter! 🎉`
    },
    {
      id: '20241203',
      expiryDate: '2024-12-10T00:00:00Z',
      message: `New Merch now available on our 👕 &nbsp;<a href='https://amazon.co.uk/extremedaytrips?ref=edts03-21' target='_blank'>Amazon Merch Store</a> &nbsp; 👚`
    },
  ], []);

  const [visibleNews, setVisibleNews] = useState([]);

  useEffect(() => {
    // Get last seen date from localStorage
    const lastSeenDate = new Date(localStorage.getItem('latestNewsSeenDate') || '1970-01-01T00:00:00Z');
    const currentDate = new Date();

    // Filter news items that are set for today or a future date and are newer than the last seen date
    const newNewsItems = newsItems.filter(news => {
      const newsDate = new Date(news.expiryDate);
      return newsDate >= currentDate && newsDate > lastSeenDate;
    });

    setVisibleNews(newNewsItems);
  }, [newsItems]);

  const handleClose = () => {
    // Store the most recent news item's date as the last seen date
    if (visibleNews.length > 0) {
      const latestNewsDate = visibleNews[0].expiryDate;
      localStorage.setItem('latestNewsSeenDate', latestNewsDate);
    }
    setVisibleNews([]);
  };

  if (visibleNews.length === 0) return null;

  return (
    <div className="news-banner">
      {visibleNews.map((news, index) => (
        <p key={index}>
        <p key={index} dangerouslySetInnerHTML={{ __html: `🆕 ${news.message}` }} />
        </p>
      ))}
      <FontAwesomeIcon icon={faClose} onClick={handleClose} className="close-icon" />
    </div>
  );
};

export default NewsBanner;
