import React, { useState, useContext, useEffect } from 'react';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import { createUser } from './SignupProcessor';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../AuthContext'; // Import AuthContext
import '../styles/style.css'; 
import { Helmet } from 'react-helmet';

const Signup = () => {
  const [step, setStep] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSocialSignIn, setIsSocialSignIn] = useState(false); // Track if social sign-in was used
  const [signupComplete, setSignupComplete] = useState(false); // Track signup completion

  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    airport: '',
    marketingConsent: false,
    flightNewsletterUpdates: false,
    terms: false,
  });

  useEffect(() => {
    if (currentUser && signupComplete) {
      navigate('/search');
    }
  }, [currentUser, signupComplete, navigate]);

  const setUserDetails = ({ email, firstName, lastName }) => {
    setFormData((prevData) => ({
      ...prevData,
      email,
      firstName,
      lastName,
    }));
    setIsSocialSignIn(true); // Mark that the user used social sign-in
    setStep(2); // Directly go to Step Two after social login
  };

  const nextStep = () => setStep(step + 1);
  const prevStep = () => setStep(step - 1);

  const handleChange = (input) => (e) => {
    setFormData({ ...formData, [input]: e.target.value });
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      await createUser(formData.email, formData.password, {
            firstName: formData.firstName,
            lastName: formData.lastName,
            email: formData.email,
            airport: formData.airportCode,
            flightNewsletterUpdates: formData.flightNewsletterUpdates,
            marketingConsent: formData.marketingConsent,
            terms: formData.terms,
            source: 'website',
          }, isSocialSignIn); // Pass the isSocialSignIn flag here
          
      
      setSignupComplete(true); // Set signup as complete after successful form submission
      navigate('/search');
    } catch (error) {
      console.error('Signup error:', error);
      alert(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <StepOne
            nextStep={nextStep}
            handleChange={handleChange}
            values={formData}
            setUserDetails={setUserDetails}
          />
        );
      case 2:
        return (
          <StepTwo
            prevStep={isSocialSignIn ? null : prevStep} // Hide back button for social sign-in users
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            values={formData}
          />
        );
      default:
        return <div>Step {step}</div>;
    }
  };

  return (
    <div className="welcome-container">
      <Helmet>
        <title>Sign Up - Extreme Day Trips</title>
        <meta name="description" content="Join Extreme Day Trips by signing up. Create an account to access and book unique adventure trips and connect with other enthusiasts." />
        <meta name="keywords" content="sign up, Extreme Day Trips, create account, adventure trips, travel community" />
        <meta property="og:title" content="Sign Up - Extreme Day Trips" />
        <meta property="og:description" content="Start your journey with Extreme Day Trips. Sign up to discover and book the most exciting day-long trips around the world." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://extremedaytrips.com/signup" />
        <meta property="og:image" content="https://extremedaytrips.com/path-to-your-og-image.jpg" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Sign Up - Extreme Day Trips" />
        <meta name="twitter:description" content="Create your account at Extreme Day Trips and embark on thrilling adventures. Sign up now to join our community of explorers." />
        <meta name="twitter:image" content="https://extremedaytrips.com/path-to-your-twitter-card-image.jpg" />
        {/* Add any additional SEO related tags as needed */}
      </Helmet>
      <div className="back-arrow" onClick={() => navigate('/')}>Back</div>
      {renderStep()}
    </div>
  );
};

export default Signup;
