// src/components/TripCard.js
import React, { useState } from 'react';
import useImageFetcher from '../utilities/ImageFetcher';
import '../styles/FlightInfoCard.css';
import { BounceLoader } from 'react-spinners';
import Flag from 'react-world-flags';  // Import the flag component


const FlightInfoCard = ({ tripData }) => {
  const {
    arrivalAirport,
    departureAirport,
    departureDateTime,
    returnDateTime,
    destinationCity,
    arrivalCity,
    flightNumber,
    price,
    bookingToken,
    userUID,
    destinationCountryCode
  } = tripData;

  const city = destinationCity || arrivalCity;
  const cityForImage = city ? city.replace(/\s+/g, '-').toLowerCase() : 'default-city';
  const imageUrl = useImageFetcher(cityForImage);
  const [isLoading, setIsLoading] = useState(true);  // New state for tracking image loading

  const formatDateForDeepLink = (dateString) => {
    const date = new Date(dateString);
    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
  }; 
const formatDate = (dateString) => {
  try {
    const date = new Date(dateString);
    if (isNaN(date)) throw new Error('Invalid date');

    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const daySuffix = (day) => {
      if (day > 3 && day < 21) return 'th';
      return ['th', 'st', 'nd', 'rd'][day % 10] || 'th';
    };
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    return `${days[date.getDay()]} ${date.getDate()}${daySuffix(date.getDate())} ${months[date.getMonth()]} ${hours}:${minutes}`;
  } catch (e) {
    return dateString;
  }
};


  const isBookable = departureAirport && arrivalAirport && departureDateTime && !userUID;
  const departureDateForDeepLink = departureDateTime ? formatDateForDeepLink(departureDateTime) : '';
  const returnDateForDeepLink = returnDateTime ? formatDateForDeepLink(returnDateTime) : '';

  const handleBookNowClick = () => {
    if (bookingToken) {
      console.log(`Booking token: ${bookingToken}`);
    } else {
      console.log('No booking token available.');
    }
  };

  // Determine the deep link URL based on whether bookingToken is available
  const getBookingUrl = () => {
    // Check if flight number contains 'fr'
    if (flightNumber.toLowerCase().includes('fr')) {
      return 'https://www.ryanair.com';
    }

    // Existing logic for Kiwi link
    return bookingToken
      ? `https://www.kiwi.com/booking?affilid=airporttransittipsdeeplinkdaytrip&token=${bookingToken}`
      : `https://www.kiwi.com/deep?affilid=airporttransittipsdeeplinkdaytrip&currency=GBP&departure=${departureDateForDeepLink}&destination=${arrivalAirport}&lang=en&origin=${departureAirport}&return=${returnDateForDeepLink}&returnFromDifferentAirport=false&returnToDifferentAirport=false`;
  };

  return (
    <div className="trip-card">
      <div className="trip-image">
        {isLoading && (
          <div className="loader-container"> {/* You may need to style this container */}
            <BounceLoader loading={isLoading} color={"#0056b3"} size={50} />
          </div>
        )}
        <img
          src={imageUrl}
          alt={`View of ${city}`}
          style={{ display: isLoading ? 'none' : 'block' }} // Hide img element while loading
          onLoad={() => setIsLoading(false)}  // Set loading to false when image has loaded
        />
      </div>
      <div className="trip-content">
        {userUID && <div className="user-submitted-pill">User Submitted</div>}
        <div className="trip-info">
          <h3>{city}
             {destinationCountryCode && (
            <Flag code={destinationCountryCode} height="16" style={{ marginLeft: '8px'}} />
          )}
          </h3>
          <table className="trip-info-table">
                    <tbody>
                      <tr><td className='trip-info-label'>Departure:</td><td className='trip-info-data'>{departureAirport}</td></tr>
                      <tr><td className='trip-info-label'>Arrival:</td><td className='trip-info-data'>{arrivalAirport}</td></tr>
                      <tr><td className='trip-info-label'>Outbound:</td><td className='trip-info-data'>{formatDate(departureDateTime)}</td></tr>
                      <tr><td className='trip-info-label'>Inbound:</td><td className='trip-info-data'>{formatDate(returnDateTime)}</td></tr>
                      <tr><td className='trip-info-label'>Flight No:</td><td className='trip-info-data'>{flightNumber}</td></tr>
                      <tr><td className='trip-info-label'>Price:</td><td className='trip-info-data'>£{parseFloat(price).toFixed(2)}</td></tr>
                    </tbody>
                  </table>
        </div>
        {isBookable && (
        <a
          href={getBookingUrl()}
          className="book-now-button"
          target="_blank"
          rel="noopener noreferrer"
          onClick={handleBookNowClick}
        >
          Book Now
        </a>
      )}
      </div>
    </div>
  );
};

export default FlightInfoCard;
