import React from 'react';
import './styles/App.css';
import './styles/Chat.css';
import AppNavigator from './AppNavigator';
import { AuthProvider } from './AuthContext';

function App() {
  return (
    <AuthProvider>
    <div className="App">
      <AppNavigator /> {/* Main App Navigator */}
    </div>
    </AuthProvider>
  );
}

export default App;
